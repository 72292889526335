const values1 = [
  ['76.5%', '43.8%'],
  ['102.0%', '61.2%'],
  ['97.9%', '25.0%'],
  ['83.3%', '2.4%'],
  ['78.3%', '2.4%'],
  ['74.0%', '34.0%'],
  ['60.0%', '63.2%'],
  ['52.5%', '69.7%'],
  ['48.8%', '58.0%'],
  ['45.4%', '36.6%'],
  ['25.4%', '77.5%'],
  ['-2.0%', '64.3%'],
  ['15.9%', '102.4%'],
  ['26.1%', '76.6%'],
  ['76.7%', '97.8%'],
  ['74.2%', '46.5%'],
];

const values2 = [
  ['74.9%', '45.5%'],
  ['100.7%', '62.5%'],
  ['99.6%', '24.9%'],
  ['86.1%', '-1.9%'],
  ['80.0%', '0.1%'],
  ['71.2%', '31.4%'],
  ['61.7%', '61.3%'],
  ['52.8%', '68.3%'],
  ['47.5%', '58.2%'],
  ['43.9%', '35.3%'],
  ['27.6%', '75.3%'],
  ['-2.1%', '65.3%'],
  ['18.7%', '102.0%'],
  ['25.8%', '74.8%'],
  ['77.0%', '95.4%'],
  ['72.0%', '45.7%'],
];

const values3 = [
  ['72.2%', '46.4%'],
  ['99.2%', '60.5%'],
  ['99.7%', '25.5%'],
  ['83.6%', '-1.6%'],
  ['79.0%', '0.9%'],
  ['72.6%', '32.3%'],
  ['61.1%', '60.5%'],
  ['50.0%', '66.8%'],
  ['49.2%', '57.2%'],
  ['45.4%', '34.0%'],
  ['27.3%', '75.5%'],
  ['-1.9%', '63.7%'],
  ['19.0%', '100.6%'],
  ['27.0%', '77.7%'],
  ['75.6%', '95.3%'],
  ['75.6%', '42.5%'],
];

const values4 = [
  ['74.6%', '45.5%'],
  ['98.7%', '60.3%'],
  ['95.3%', '25.4%'],
  ['87.6%', '-0.1%'],
  ['81.8%', '3.3%'],
  ['72.0%', '32.3%'],
  ['62.6%', '64.4%'],
  ['53.9%', '65.8%'],
  ['48.5%', '60.4%'],
  ['44.4%', '35.7%'],
  ['29.2%', '78.1%'],
  ['-2.1%', '64.2%'],
  ['19.5%', '102.3%'],
  ['28.3%', '75.6%'],
  ['76.7%', '97.8%'],
  ['75.0%', '46.3%'],
];

const allValues = [values1, values2, values3, values4];

// const randomClipPath = () => {
//   const clipPathValues = [
//     ['74.1%', '44.1%'],
//     ['100%', '61.6%'],
//     ['97.5%', '26.9%'],
//     ['85.5%', '0.1%'],
//     ['80.7%', '2%'],
//     ['72.5%', '32.5%'],
//     ['60.2%', '62.4%'],
//     ['52.4%', '68.1%'],
//     ['47.5%', '58.3%'],
//     ['45.2%', '34.5%'],
//     ['27.5%', '76.7%'],
//     ['0.1%', '64.9%'],
//     ['17.9%', '100%'],
//     ['27.6%', '76.8%'],
//     ['76.1%', '97.7%'],
//     ['74.1%', '44.1%'],
//   ];

//   const values = clipPathValues
//     .map(([xValue, yValue]) => {
//       const xDeviation = Math.random() * 5 - 2.5; // random deviation between -2.5 to 2.5
//       const yDeviation = Math.random() * 5 - 2.5;

//       const newXValue = `${(parseFloat(xValue) + xDeviation).toFixed(1)}%`;
//       const newYValue = `${(parseFloat(yValue) + yDeviation).toFixed(1)}%`;

//       return `${newXValue} ${newYValue}`;
//     })
//     .join(', ');

//   return values;
// };

// const randomRotation = () => {
//   const baseRotation = 30; // original value
//   const deviation = Math.random() * 10 - 5; // -5 to 5 degrees
//   return `${baseRotation + deviation}deg`;
// };

const RandomBg = ({
  addClass = '',
  index,
}: {
  addClass: string;
  index: number;
}) => {
  //   const marginLeft = randomMargin();

  const clipPath = allValues[index];
  // const [clipPath, setClipPath] = useState('');

  // useEffect(() => {
  //   setClipPath(randomClipPath()); // Set random clip path after component mounts
  // }, []);

  return (
    <div
      className={`absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden w-1/2 opacity-20 blur-3xl xl:justify-end ${addClass}`}
      aria-hidden="true"
    >
      <div
        className={`aspect-[1313/771] w-full flex-none origin-top-right bg-gradient-to-tr from-[#fca5a5] to-[#38bdf8]`}
        style={{
          //   marginLeft,
          clipPath: `polygon(${clipPath
            ?.map(([xValue, yValue]) => `${xValue} ${yValue}`)
            .join(', ')})`,
        }}
      />
    </div>
  );
};

export default RandomBg;
