import Spinner from '../graphics/Spinner';

export const promptResponseStyles =
  'py-2 px-4 border-2 text-black bg-white text-md rounded-lg border-black hover:bg-gray-50 focus:outline-none shadow-hard-down text-center';
export interface ButtonProps {
  buttonText?: string;
  isLoading?: boolean;
  // use classNames as a prop to completely overwrite
  // use appendClasses to append (and overwrite one at a time)
  appendClasses?: string;
  disabled?: boolean;
  presetStyle?:
    | 'full-width-dark'
    | 'subtle-small'
    | 'default-style'
    | 'default-subtle'
    | 'pagination'
    | 'prompt-response'
    | 'prompt-response-subtle'
    | 'no-bg-black-hover'
    | 'gradient-button'
    | 'no-style';
}

const Button = ({
  isLoading = false,
  buttonText,
  presetStyle,
  disabled = false,
  appendClasses,
  children,
  ...buttonProps
}: ButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {
  // TODO: refactor, how do other people do buttons?
  const styleDict = {
    'full-width-dark':
      'w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:bg-gray-500',
    'subtle-small':
      'rounded-full whitespace-nowrap border border-gray-300 shadow-sm px-2 py-px bg-transparent text-sm font-small text-gray-500 hover:bg-gray-200',
    'default-style':
      'border-transparent text-white bg-sky-600 hover:bg-sky-500 focus:outline-none focus:border-sky-500 focus:shadow-outline-blue active:bg-sky-500',
    'default-subtle':
      'border-gray-400 text-gray-500 bg-white hover:text-white hover:bg-gray-800 focus:outline-none focus:border-sky-500 focus:shadow-outline-blue active:bg-sky-500',
    'no-bg-black-hover':
      'p-2 text-gray-900 bg-transparent hover:bg-gray-900 hover:text-white rounded-sm flex items-center justify-center space-x-1 focus:outline-none focus:outline-offset-0',
    'prompt-response': promptResponseStyles,
    'gradient-button':
      'rounded-md border-2 border-sky-600 bg-gradient-to-r from-sky-500 to-indigo-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-sky-500 hover:to-indigo-700',
    'prompt-response-subtle':
      'py-2 px-4 border-2 text-gray-400 bg-white text-md rounded-lg border-gray-400 hover:bg-gray-300 hover:text-white focus:outline-none',
    pagination:
      'items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
  };

  if (disabled) {
    buttonProps.className = `py-2 px-4 border text-sm text-gray-400 hover:text-gray-400 font-medium rounded-md bg-gray-200 hover:bg-gray-200 ${appendClasses}`;
  } else if (presetStyle === 'no-style') {
    buttonProps.className = appendClasses;
  } else if (presetStyle?.startsWith('default-') || !presetStyle) {
    buttonProps.className = `py-2 px-4 border text-sm font-medium rounded-md ${
      presetStyle && styleDict[presetStyle]
    } ${appendClasses}`;
  } else if (styleDict[presetStyle]) {
    buttonProps.className = `${styleDict[presetStyle]} ${appendClasses}`;
  } else {
    buttonProps.className = `${styleDict['default-style']} ${appendClasses}`;
  }

  return (
    <button {...buttonProps} disabled={disabled}>
      {isLoading ? (
        <span className="flex flex-row items-center justify-center">
          <Spinner width="20" fill="#ccc" className="animate-spin mr-1" />
          <span>Loading&nbsp;</span>
        </span>
      ) : (
        <>
          <span>{buttonText}</span>
          {children}
        </>
      )}
    </button>
  );
};

// because we're using forwardRef we have to use this to give a name for dev tools

export default Button;
